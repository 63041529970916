/* global safari */

// Firefox 1.0+
const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
// Safari 3.0+ "[object HTMLElementConstructor]"
const isSafari = /constructor/i.test(window.HTMLElement) || (function checkIsSafari(p) { return p.toString() === '[object SafariRemoteNotification]'; }(!window.safari || (typeof safari !== 'undefined' && safari.pushNotification)));
// Internet Explorer 6-11
// var isIE = /*@cc_on!@*/false || !!document.documentMode;
const isIE = !!((Object.getOwnPropertyDescriptor && Object.getOwnPropertyDescriptor(window, 'ActiveXObject')) || ('ActiveXObject' in window));
// Edge 20+
const isEdge = !isIE && !!window.StyleMedia;
// Chrome 1+
const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

let detectedBrowser; let
  rDetectedVersion;
let detectedVersion = 0;

if (isFirefox) {
  detectedBrowser = 'firefox';
  detectedVersion = parseInt(navigator.userAgent.match(/Firefox\/([0-9]+)\./)[1], 10);
} else if (isChrome) {
  rDetectedVersion = /Chrom(e|ium)\/([0-9]+)\./;
  detectedBrowser = 'chrome';
  detectedVersion = rDetectedVersion.test(navigator.userAgent)
    ? parseInt(navigator.userAgent.match(rDetectedVersion)[2], 10) : 999;
} else if (isSafari) {
  rDetectedVersion = /applewebkit\/(\d+).*version\/(\d+)/i;
  detectedBrowser = 'safari';
  detectedVersion = rDetectedVersion.test(navigator.userAgent)
    ? parseInt(navigator.userAgent.match(rDetectedVersion)[2], 10) : 999;
} else if (isIE) {
  detectedBrowser = 'Internet Explorer';
} else if (isEdge) {
  detectedBrowser = 'Edge';
}

export default {
  browser: detectedBrowser,
  version: detectedVersion
};
