import DateFormatter from './format_date';
/* eslint-disable no-underscore-dangle */
const browserTzoffs = new Date().getTimezoneOffset() * 60;
/*
  Prevent the side effect of browser's timezone offset,
  We only use Date's method "getUTCxxx" to display local time.
  In this file, "long time format" in `localTimestamp` is only used to display local time.
*/
export default class LocalTime {
  constructor(options) {
    this.HOUR_12_BASE = false;
    if (typeof options === 'string') {
      this.timeStr = options;

      const timeFormat = /^(\d{4})-(\d\d)-(\d\d)T(\d\d):(\d\d):(\d\d)$/;
      const projectionTimeStrToUTC = (str) => {
        const parts = timeFormat.exec(str);
        return Date.UTC(parts[1], parseInt(parts[2], 10) - 1, parts[3],
          parts[4], parts[5], parts[6], 0);
      };

      this.localTimestamp = projectionTimeStrToUTC(options);
    } else {
      const { timestamp, tzoffs, isMeridian = this.HOUR_12_BASE } = options;

      this._timestamp = timestamp;
      this._tzoffs = tzoffs;
      this.HOUR_12_BASE = isMeridian;
      this.updateLocalTimestamp();
    }
  }

  updateLocalTimestamp() {
    this.localTimestamp = this._timestamp - this._tzoffs * 1000;
  }

  static getBrowserTimezoneOffset() {
    return browserTzoffs;
  }

  get fullYear() {
    return this._getDate().getUTCFullYear();
  }

  get month() {
    return this._getDate().getUTCMonth(); // 0 ~ 11
  }

  get date() {
    return this._getDate().getUTCDate();
  }

  get hours() {
    return this._getDate().getUTCHours();
  }

  get minutes() {
    return this._getDate().getUTCMinutes();
  }

  get seconds() {
    return this._getDate().getUTCSeconds();
  }

  get queryDate() {
    return `${this.fullYear}-${(`00${this.month + 1}`).substr(-2)}-${(`00${this.date}`).substr(-2)}`;
  }

  get queryTime() {
    return `${(`00${this.hours}`).substr(-2)}:${(`00${this.minutes}`).substr(-2)}:${(`00${this.seconds}`).substr(-2)}`;
  }

  get queryDateTimeObject(){
    return {
      year: `${this.fullYear}`,
      month: (`00${this.month + 1}`).substr(-2),
      day: (`00${this.date}`).substr(-2),
      hours: (`00${this.hours}`).substr(-2),
      minutes: (`00${this.minutes}`).substr(-2),
      seconds: (`00${this.seconds}`).substr(-2)
    }
  }

  get displayFullTime() {
    if (!this.localTimestamp) {
      return '';
    }
    const t = this.localTimestamp / 1000;
    return `${DateFormatter.localeUTCDate(t)} ${DateFormatter.localeUTCTime(t)}`;
  }

  get displayTime() {
    if (!this.localTimestamp) {
      return '';
    }
    return `${DateFormatter.localeUTCTime(this.localTimestamp / 1000)}`;
  }

  get timestamp() {
    return this._timestamp;
  }

  get tzoffs() {
    return this._tzoffs;
  }

  set timestamp(value) {
    this._timestamp = value;
    this.updateLocalTimestamp();
  }

  set tzoffs(value) {
    this._tzoffs = value;
    this.updateLocalTimestamp();
  }

  _getDate() {
    return new Date(this.localTimestamp);
  }

  valueOf() {
    return this.localTimestamp;
  }

  toString() {
    return `${this.queryDate}T${this.queryTime}`;
  }

  forwardMilliseconds(msec) {
    const newTime = this.projection();

    newTime.timestamp += msec;

    return new LocalTime(newTime);
  }

  projection(tzoffs) {
    // eslint-disable-next-line no-param-reassign
    tzoffs = tzoffs ?? this._tzoffs ?? browserTzoffs;

    return {
      timestamp: this.localTimestamp + tzoffs * 1000, tzoffs
    };
  }
}
