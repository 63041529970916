import { Snapshot, RtspProtocol } from '@vivotek/lib-medama';
import SnapshotStorage from '@/utility/snapshotIndexedDB';
import PLUGINFREE from '@/constants/pluginfree';

const MIN_SNAPSHOT_SIZE = 10000;

const pcol = (window.location.protocol === 'https:' ? 'wss:' : 'ws:');
const wsSrc = `${pcol}//${window.location.host}/ws/module_over_ws`;

const actions = {
  createRtspChannel({ state, commit }) { // from PluginlessWrapper
    return new Promise((resolve, reject) => {
      const rtspWebsocket = new WebSocket(wsSrc, 'vvtk-protocol');
      rtspWebsocket.onclose = () => reject(new Error('WebSocket disconnect'));
      rtspWebsocket.onopen = () => {
        const rtspChannel = new RtspProtocol(rtspWebsocket);
        commit('setRtspChannel', rtspChannel);
        resolve(rtspChannel);
      };
    });
  },
  closeRtspChannel({ state, commit }) {
    if (!state.rtspChannel) {
      return;
    }
    state.rtspChannel.close();
    commit('setRtspChannel', null);
  },
  getRtspChannel({ state, dispatch }) { // from PluginlessWrapper
    if (state.rtspChannel) {
      return Promise.resolve(state.rtspChannel);
    }

    return dispatch('createRtspChannel');
  },
  async getSnapshot({ state, dispatch, commit }, {
    channel, streamType, snapshotTime, width, height
  }) {
    const snapshotIndex = `${channel}-${streamType}-${snapshotTime}`;
    const result = await SnapshotStorage.get(snapshotIndex);
    const isSnapshotExist = result !== undefined;
    const mode = snapshotTime === 0 ? 'liveview' : 'playback';
    const maxAvailableTime = mode === 'playback' ? SnapshotStorage.MAX_AVAILABLE_TIME : 30 * 1000;
    if (isSnapshotExist && (Date.now() - result.created) < maxAvailableTime) {
      return result.content;
    }

    return new Promise((resolve, reject) => {
      const fetchSnapshot = (snapshotProps) => dispatch('fetchSnapshot', snapshotProps)
        .then((blob) => {
          if (blob.size < MIN_SNAPSHOT_SIZE) {
            reject(new Error('Error: Incorrect Snapshot Size')); // unknown cause for getting snapshot size too small
            return;
          }
          const key = `${snapshotProps.channel}-${snapshotProps.streamType}-${snapshotProps.snapshotTime}`;
          if (isSnapshotExist) {
            SnapshotStorage.updateObject(key, blob);
          } else {
            SnapshotStorage.addObject(key, blob);
          }
          resolve(blob);
        }).catch((err) => reject(err))
        .finally(() => Promise.resolve());

      commit('processTask', {
        task: fetchSnapshot,
        parameters: {
          channel, streamType, snapshotTime, width, height, mode
        },
      });
    });
  },
  async fetchSnapshot({ dispatch, rootState }, {
    channel, streamType, snapshotTime, width, height, mode
  }) {
    let snapshotInstance = await dispatch('createSnapshotInstance', { channel, streamType, mode });
    return new Promise((resolve, reject) => {
      snapshotInstance.on('error', (err) => {
        reject(err);
      });
      const snapshotOpt = {
        startTime: snapshotTime,
        resolution: { width, height }
      };
      snapshotInstance.getImage(snapshotOpt)
        .then(resolve)
        .finally(() => {
          snapshotInstance.destroy();
          snapshotInstance = null;
        });
    });
  },
  createSnapshotInstance({ dispatch, rootState }, { channel, streamType, mode = 'playback' }) {
    return dispatch('getRtspChannel')
      .then((rtspChannel) => {
        const stream = streamType === 'main' ? '' : '_Sub';
        const url = mode === 'playback'
          ? `rtsp://localhost//Media/Database/Normal?LOCATION=Loc${channel}${stream}&fullspeedbeforejam=yes&knowgoodbye=true`
          : `rtsp://localhost/Media/Live/Normal?camera=C_${channel}&streamtype=${streamType}`;
        const snapshotInstance = new Snapshot({
          url,
          rtspChannel,
          username: rootState.user.userName,
          sessionId: rootState.user.sessionId,
          workerLibde264Path: PLUGINFREE.WORKER_264_SRC,
          workerLibde265Path: PLUGINFREE.WORKER_265_SRC
        });
        // snapshotInstance.on('error', this.handleSnapshotError);
        return snapshotInstance;
      });
  },
};

export default actions;
