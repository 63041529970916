import { render, staticRenderFns } from "./DisplayStatusBar.vue?vue&type=template&id=7044f961&scoped=true&"
import script from "./DisplayStatusBar.vue?vue&type=script&lang=js&"
export * from "./DisplayStatusBar.vue?vue&type=script&lang=js&"
import style0 from "./DisplayStatusBar.vue?vue&type=style&index=0&id=7044f961&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7044f961",
  null
  
)

export default component.exports