import { RtspProtocol } from '@vivotek/lib-medama';
import RTCPager from './rtc/rtc_pager';

function createWebSocket(address, protocol = 'vvtk-protocol') {
  return new Promise((resolve, reject) => {
    const rtspWebSocket = new WebSocket(address + '/ws/module_over_ws', protocol);
    const rtspChannel = new RtspProtocol(rtspWebSocket);

    const promiseReject = () => reject(new Error('WebSocket disconnect'));

    rtspChannel.on('close', promiseReject);
    rtspWebSocket.onopen = () => {
      rtspChannel.off('close', promiseReject);
      resolve(rtspChannel);
    };
  });
}

function createRtcConnection(device) {
  return new Promise((resolve, reject) => {
    const rtcPager = new RTCPager(device);
    rtcPager.on('success', (connection) => {
      resolve(connection);
    });
    rtcPager.on('failed', (error) => {
      reject(error);
    });
  });
}

function sendRequest(device, request) {
  return createRtcConnection(device)
    .then((connection) => {
      const httpConnection = connection.getHttpChannel();
      return httpConnection.send(request);
    });
}

export default {
  createWebSocket,
  createRtcConnection,
  sendRequest,
};
