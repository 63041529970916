import RTCClient from './rtc_client';

const pool = {};

function createRTCClient(deviceId, onlineRegion) {
  return new Promise((resolve, reject) => {
    const rtcConnection = new RTCClient({
      deviceId,
      onlineRegion
    });

    console.log(`Create RTCConnection with deviceId: ${deviceId}, onlineRegion: ${onlineRegion}`);

    pool[deviceId] = rtcConnection;

    rtcConnection.on('connected', () => {
      console.log(`RTCConnection creation success`);
      resolve(rtcConnection);
    });

    rtcConnection.on('abort', (msg) => {
      console.warn(`RTCConnection creation aborted: ${msg}`);
      pool[deviceId].close();
      delete pool[deviceId];
      let abortError = msg;
      if (typeof abortError === 'string') {
        abortError = new Error(`fail to create RTCConnection: ${msg}`);
      }
      reject(abortError);
    });

    rtcConnection.on('close', () => {
      console.log('WebRTC connect close');
      delete pool[deviceId];
    });

    rtcConnection.connect();
  });
}

let permissionDeniedCallback = function permissionDeniedCallback() {};

export default {
  onPermissionDenied(cb) {
    permissionDeniedCallback = cb;
  },
  createRTCConnection({deviceId, onlineRegion }) {
     if (pool[deviceId]) {
      if (pool[deviceId].connected) {
        console.log(`${deviceId}'s RTCConnection exists and is connected`);
        return Promise.resolve(pool[deviceId]);
      }
      pool[deviceId].close();
    }

    return createRTCClient(deviceId, onlineRegion)
      .catch((err) => {
        console.error('createRTCClient failed:', err);
        if (err?.message === 'permission denied') {
          setTimeout(() => permissionDeniedCallback(deviceId), 0);
        }
        return Promise.reject(err);
      });
  },
  disconnectRTCConnection(deviceId) {
    console.warn('RTCConnection try to disconnect');
    if (pool[deviceId]) { pool[deviceId].close(); }
  },
  cleanRTCConnection() {
    Object.keys(pool).forEach((deviceId) => {
      this.disconnectRTCConnection(deviceId);
    });
  }
};
