import MicroEvent from '@vivotek/lib-utility/microevent';
import { RtspProtocol, Liveview, PLUGINFREE } from '@vivotek/lib-medama';

class LiveviewV3 {
  constructor(options) {
    this.options = options;

    this.rtcConnection = options.rtcConnection;
    this.target = options.target || 'localhost';
    this.path = options.path || '';
    this.url = `rtsp://${this.target}${this.path}`;
    this.isFisheye = false;

    this.SUPPORT_VIDEO_CODEC = PLUGINFREE.SUPPORT_VIDEO_CODEC || [];
    this.SUPPORT_AUDIO_CODEC = PLUGINFREE.SUPPORT_AUDIO_CODEC || [];

    this.processor = this.createProcessor(options);
    this.bindEvents();
  }

  createProcessor(options) {
    return new Liveview({
      url: this.url,
      rtspChannel: this.getRtspChannel(),
      mute: !!options.mute,
      volume: options.volume || 0,
      workerLibde265Path: options.workerLibde265Path,
    });
  }

  bindEvents() {
    const { processor } = this;

    this.$_onPlay = this.onPlay.bind(this);
    this.$_onStop = this.onStop.bind(this);
    this.$_onNotify = this.onNotify.bind(this);
    this.$_onError = this.onError.bind(this);

    processor.on('play', this.$_onPlay);
    processor.on('stop', this.$_onStop);
    processor.on('notify', this.$_onNotify);
    processor.on('error', this.$_onError);
  }

  unbindEvents() {
    const { processor } = this;

    processor.off('play', this.$_onPlay);
    processor.on('stop', this.$_onStop);
    processor.on('notify', this.$_onNotify);
    processor.on('error', this.$_onError);

    delete this.$_onPlay;
    delete this.$_onStop;
    delete this.$_onNotify;
    delete this.$_onError;
  }

  onPlay(evt) {
    this.trigger('play', evt);
  };

  onStop(evt) {
    this.trigger('stop', evt);
    if (this.rtspChannel) {
      this.rtcConnection.resetLiveviewChannel(this.rtspChannel);
      delete this.rtspChannel;
    }
  };

  onError(error) {
    this.trigger('error', error);
  };

  onNotify(notify) {
    if (notify.error) {
      this.trigger('error', notify.error);
      return;
    }

    if (notify.codec) {
      this.trigger('codec', notify.codec);
    }

    if (notify.timestamp.stream) {
      this.trigger('timestamp', {
        tzoffs: notify.timestamp.tzoffs,
        timestamp: notify.timestamp.stream
      });
    }

    // di event
    if (notify.di_status && notify.di_status.some((d) => !!d)) {
      this.trigger('di', notify.di_status);
    }
    // do event
    if (notify.do_status && notify.do_status.some((d) => !!d)) {
      this.trigger('do', notify.do_status);
    }

    // motion_window event
    if (notify.motion_window
        && notify.motion_window.length > 0
        && notify.motion_window.some((m) => m.setting && m.trigger)) {
      const cropWindow = {
        width: notify.capture_window.crop_width || notify.capture_window.width,
        height: notify.capture_window.crop_height || notify.capture_window.height
      };

      this.trigger('motion', notify.motion_window.filter((m) => m.setting && m.trigger), cropWindow);
    }

    this.isFisheye = !!notify.fisheye;
    // fisheye event
    if (notify.fisheye) {
      this.trigger('fisheye', notify.fisheye, notify.resolution);
    }
  };

  getRtspChannel() {
    if (this.rtspChannel) {
      return this.rtspChannel;
    }
    this.rtspChannel = this.rtcConnection.getLiveviewChannel();
    return this.rtspChannel;
  }

  getSupportVideoCodec() {
    return this.SUPPORT_VIDEO_CODEC;
  }

  getSupportAudioCodec() {
    return this.SUPPORT_AUDIO_CODEC;
  }

  getPlayer() {
    return this.processor?.getPlayer();
  }

  play() {
    const { processor } = this;

    if (!processor) {
      return Promise.reject();
    }

    return processor.play();
  }

  stop() {
    if (!this.processor) {
      return Promise.resolve();
    }
    return this.processor.stop();
  }

  setVolume(volume = 0) { // volume from 0 ~ 100
    if (!this.processor) {
      return;
    }

    this.processor.setVolume(volume / 100);
  }

  destroy () {
    if (this.processor) {
      this.unbindEvents();
      this.processor.destroy();
      delete this.processor;
    }
  }
};

export default MicroEvent.mixin(LiveviewV3);
