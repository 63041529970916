export default function formatDatetime(date) {
  return [
    (`0000${date.getUTCFullYear()}`).substr(-4),
    (`00${date.getUTCMonth() + 1}`).substr(-2),
    (`00${date.getUTCDate()}`).substr(-2),
    '_',
    (`00${date.getUTCHours()}`).substr(-2),
    (`00${date.getUTCMinutes()}`).substr(-2),
    (`00${date.getUTCSeconds()}`).substr(-2),
    '.',
    (`000${date.getUTCMilliseconds()}`).substr(-3)
  ].join('');
}
