import Vue from 'vue';
import axios from 'axios';
import store from '@/store';

import SvgIcon from '@vivotek/lib-svg-icon';
import KeepAliveService from '@/services/KeepAliveApi';

Vue.use(SvgIcon);

store.dispatch('user/checkLoginVerified').then(() => {
  if (!store.getters['user/isPasswordVerified'] || !store.getters['user/isTmisVerified']) {
    window.location = '/index.html';
  }
}).catch(() => {
  window.location = '/index.html';
});

axios.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    location.href = '/login.html';
    return error;
  }
  return Promise.reject(error);
});

window.addEventListener('keydown', e => {
  KeepAliveService.keepAlive();
}, true);

window.addEventListener('click', e => {
  KeepAliveService.keepAlive();
}, true);
