import analytic from '../utils/analytic';
import detectedBrowser from '@vivotek/lib-utility/detected_browser';
import MicroEvent from '@vivotek/lib-utility/microevent';

const isIE = detectedBrowser.browser === 'Internet Explorer';

function RTCPeerClient(options) {
  const self = this;
  let offerSent = false;
  const iceBuffer = [];

  const iceServers = options.iceServers || [{ urls: 'stun:stun.l.google.com:19302' }];

  this.connected = false;

  const pc = new RTCPeerConnection({ iceServers }, { optional: [] });
  this.pc = pc;

  if (!pc || pc.iceConnectionState === '') {
    this.trigger('close', 'create RTCPeerConnection fail');
    return;
  }

  pc.onicecandidate = function onIceCandidate(evt) {
    if (!evt.candidate) { return; }

    if (evt.candidate.address && evt.candidate.address.indexOf('.local') >= 0) { return; }

    const candidate = {
      candidate: evt.candidate.candidate,
      sdpMLineIndex: 0,
      sdpMid: 'data'
    };

    if (offerSent) {
      this.trigger('icecandidate', candidate);
    } else {
      iceBuffer.push(candidate);
    }
  }.bind(this);

  const pendingDataChannels = {};
  const dataChannels = {};
  let checkInterval;
  let dataChannelSettings;

  if (options.defaultDataChannels) {
    dataChannelSettings = options.defaultDataChannels;
  } else {
    dataChannelSettings = {
      data: {},
      cache: {},
      volalarm: {}
    };
  }

  const channels = Object.keys(dataChannelSettings);

  function unbindDataChannel(dataChannel) {
    if (!dataChannel) { return; }

    dataChannel.onopen = null;
    dataChannel.onmessage = null;
    dataChannel.onclose = null;
    dataChannel.onerror = null;
    dataChannel.on = null;
    dataChannel.off = null;
    dataChannel.trigger = null;
    // eslint-disable-next-line no-underscore-dangle
    dataChannel._events = null;
  }

  channels.forEach((label) => {
    const channelOptions = dataChannelSettings[label];
    pendingDataChannels[label] = MicroEvent.mixin(pc.createDataChannel(label, channelOptions));
    const channel = pendingDataChannels[label];
    // extend Class MicroEvent

    console.log('try to open dataChennel:', label);

    channel.binaryType = 'arraybuffer';
    channel.onopen = function onOpen() {
      console.log('dataChannel: ', label, 'opened');

      dataChannels[label] = channel;

      delete pendingDataChannels[label];
      if (Object.keys(dataChannels).length === channels.length) {
        self.trigger('connected', dataChannels);

        self.connected = true;
        console.log('RTCClient connected');

        checkInterval = setInterval(() => {
          if (pc.iceConnectionState !== 'disconnected') { return; }

          clearInterval(checkInterval);
          self.trigger('close');

          self.connected = false;
        }, 200);
      }
    };

    channel.onmessage = function onMessage(evt) {
      this.trigger('message', evt);
    }.bind(channel);

    channel.onclose = function onClose(evt) {
      this.trigger('close', evt);

      unbindDataChannel(this);

      if (dataChannels[label] === this) {
        delete dataChannels[label];
        console.log('dataChannel: ', label, 'close');
      }
    }.bind(channel);
  });

  const createOfferSuccess = function createOfferSuccess(desc) {
    pc.setLocalDescription(desc);

    self.trigger('offer', desc);
  };

  const createOfferFail = function createOfferFail(error) {
    console.error(`Failed to create session description: ${error.toString()}`);
  };

  if (isIE) {
    pc.createOffer(createOfferSuccess, createOfferFail);
  } else {
    // for firefox, chrome and safari
    pc.createOffer().then(createOfferSuccess, createOfferFail);
  }

  this.setRemoteDescription = function setRemoteDescription(desc) {
    const sessionDesc = new RTCSessionDescription(desc);
    pc.setRemoteDescription(sessionDesc);
  };

  this.addIceCandidate = function addIceCandidate(candidate) {
    if (!candidate) { return; }

    if (candidate.sdpMid) {
      delete candidate.sdpMid;
    }

    const iceCandidate = new RTCIceCandidate(candidate);

    pc.addIceCandidate(iceCandidate, () => {
      // console.log('AddIceCandidate success.');
    }, (error) => {
      console.error(`Failed to add Ice Candidate: ${error.toString()}`);
    });
  };

  this.allowSendIce = function allowSendIce(offerIsSent) {
    offerSent = (offerIsSent === undefined) ? true : !!offerIsSent;

    if (!offerSent) { return; }

    while (iceBuffer.length > 0) {
      self.trigger('icecandidate', iceBuffer.shift());
    }
  };

  this.getConnectionType = function getConnectionType(success, fail) {
    function standardizeReport(response) {
      const standardReport = {};

      if (isIE) {
        response.result().forEach((report) => {
          const standardStats = {
            id: report.id,
            type: report.type
          };
          report.names().forEach((name) => {
            standardStats[name] = report.stat(name);
          });
          standardReport[standardStats.id] = standardStats;
        });
      } else {
        response.forEach((report) => {
          standardReport[report.id] = report;
        });
      }

      return standardReport;
    }

    return new Promise((resolve, reject) => {
      if (isIE) {
        this.pc.getStats(null, (response) => {
          const report = response.result().toArray();
          let type = 'turn';
          let candidatePair;

          Object.keys(report).forEach((key) => {
            const state = report[key];

            if (state.type !== 'googCandidatePair'
                || state.stat('googActiveConnection') !== 'true') { return; }

            candidatePair = state;
          });

          const localType = candidatePair.stat('googLocalCandidateType');
          const remoteType = candidatePair.stat('googRemoteCandidateType');
          if (localType !== 'relay' && remoteType !== 'relay') {
            type = 'stun';
          }

          analytic.track('webrtc-connection-type', {
            browser: detectedBrowser.browser,
            browserVersion: detectedBrowser.version,
            localType,
            remoteType,
            determinedType: type,
          });

          resolve(type);
        }, reject);
      } else {
        // In Chrome/Firefox
        this.pc.getStats().then((response) => {
          const report = standardizeReport(response);
          let determinedType = 'turn';
          let remoteCandidateId;
          let localCandidateId;

          response.forEach((res) => {
            if (res.type !== 'candidate-pair' || res.state !== 'succeeded') { return; }
            remoteCandidateId = res.remoteCandidateId;
            localCandidateId = res.localCandidateId;
          });

          const localType = report[localCandidateId] ? report[localCandidateId].candidateType : 'unknown';
          const remoteType = report[remoteCandidateId] ? report[remoteCandidateId].candidateType : 'unknown';
          const isStun = [localType, remoteType].every((type) => !/relay(ed)?$/.test(type));

          if (localType === 'unknown' || remoteType === 'unknown') {
            determinedType = 'unknown';
          } else if (isStun) {
            determinedType = 'stun';
          }

          analytic.track('webrtc-connection-type', {
            browser: detectedBrowser.browser,
            browserVersion: detectedBrowser.version,
            localType,
            remoteType,
            determinedType
          });

          resolve(determinedType);
        }).catch((e) => {
          console.log('getStats failed...');
          console.error(e);
          analytic.track('webrtc-connection-type', {
            browser: detectedBrowser,
            browserVersion: detectedBrowser.version,
            localType: 'N/A',
            remoteType: 'N/A',
            determinedType: 'failed',
          });
          reject();
        });
      }
    });
  };

  this.close = function close() {
    pc.close();
  };

  this.createDataChannel = function createDataChannel(label, channelOptions) {
    const channelName = `${label}-${Math.random().toString(36).substring(2, 10)}`;
    const channel = MicroEvent.mixin(this.pc.createDataChannel(channelName, channelOptions));
    console.log('try to open dataChennel:', label);
    return new Promise((resolve) => {
      channel.binaryType = 'arraybuffer';
      channel.onopen = function onOpen() {
        console.log('dataChannel:', label, 'opened');

        dataChannels[label] = channel;

        resolve(channel);
      };

      channel.onmessage = function onMessage(evt) {
        this.trigger('message', evt);
      }.bind(channel);

      channel.onclose = function onClose(evt) {
        this.trigger('close', evt);

        unbindDataChannel(this);

        if (dataChannels[label] === this) {
          delete dataChannels[label];
          console.log('dataChannel: ', label, 'close');
        }
      }.bind(channel);
    });
  };
}

export default MicroEvent.mixin(RTCPeerClient);
