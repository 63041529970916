var padL2x0 = function (str) {
  return ('00' + str).substr(-2);
};

var localDateTimeMethods = {
  'year': 'getFullYear',
  'month': 'getMonth',
  'date': 'getDate',
  'hours': 'getHours',
  'minutes': 'getMinutes',
  'seconds': 'getSeconds'
};
var UTCDateTimeMethods = {
  'year': 'getUTCFullYear',
  'month': 'getUTCMonth',
  'date': 'getUTCDate',
  'hours': 'getUTCHours',
  'minutes': 'getUTCMinutes',
  'seconds': 'getUTCSeconds'
};

var formatString = {
  'date': 'yy/mm/dd',
  'time': 'HH:mm:ss'
};

var gen_formatDate = function(map) {
  var getField = function (d, f){
    return d[map[f]]();
  };
  return function(format) {
    format = format || formatString.date;

    var date = this;
    var month = getField(date, 'month') + 1;
    var year = getField(date, 'year');

    if (format.indexOf('mm') > -1) {
      format = format.replace('mm',padL2x0(month.toString()));
    }
    else if (format.indexOf('m') > -1) {
      format = format.replace('m',month.toString());
    }

    if (format.indexOf('yy') > -1) {
      format = format.replace('yy',year.toString());
    }
    else if (format.indexOf('y') > -1) {
      format = format.replace('y',year.toString().substr(2,2));
    }

    if(format.indexOf('dd') > -1) {
      format = format.replace('dd',padL2x0(getField(date, 'date').toString()));
    }
    else if(format.indexOf('d') > -1) {
      format = format.replace('d',getField(date, 'date').toString());
    }

    return format;
  };
};

var gen_formatTime = function(map) {
  var getField = function (d, f){
    return d[map[f]]();
  };
  return function(time, format) {
    var hours = getField(time, 'hours');

    if (format.indexOf('tt') > -1) {
      if (hours > 11) {
        format = format.replace('tt','pm');
      }
      else {
        format = format.replace('tt','am');
      }
    }
    else if (format.indexOf('TT') > -1) {
      if (hours > 11) {
        format = format.replace('TT','PM');
      }
      else {
        format = format.replace('TT','AM');
      }
    }
    else if (format.indexOf('cc') > -1) {
      if (hours > 11) {
        format = format.replace('cc','下午');
      }
      else {
        format = format.replace('cc','上午');
      }
    }

    if (format.indexOf('HH') > -1) {
      format = format.replace('HH', padL2x0(hours.toString()));
    }
    else if (format.indexOf('H') > -1) {
      format = format.replace('H', hours.toString());
    }

    if (format.indexOf('hh') > -1) {
      if (hours > 12) {
        hours -= 12;
      }
      if (hours === 0) {
        hours = 12;
      }
      format = format.replace('hh', padL2x0(hours.toString()));
    }
    else if (format.indexOf('h') > -1) {
      if (hours > 12) {
        hours -= 12;
      }
      if (hours === 0) {
        hours = 12;
      }
      format = format.replace('h',hours.toString());
    }

    if (format.indexOf('mm') > -1) {
      format = format.replace('mm',padL2x0(getField(time, 'minutes').toString()));
    }
    else if (format.indexOf('m') > -1) {
      format = format.replace('m',getField(time, 'minutes').toString());
    }

    if (format.indexOf('ss') > -1) {
      format = format.replace('ss',padL2x0(getField(time, 'seconds').toString()));
    }
    else if (format.indexOf('s') > -1) {
      format = format.replace('s',getField(time, 'seconds').toString());
    }

    return format;
  };
};

var _formatDate = gen_formatDate(localDateTimeMethods);
var _formatUTCDate =  gen_formatDate(UTCDateTimeMethods);
var _formatTime = gen_formatTime(localDateTimeMethods);
var _formatUTCTime = gen_formatTime(UTCDateTimeMethods);

var formatUTCDate = function(pure_date, format){
  return _formatUTCDate.call(pure_date, format || formatString.date);
};
var formatUTCTime = function(pure_time, format){
  return _formatUTCTime(pure_time, format || formatString.time);
};
var localeUTCDate = function(timestamp){
  return _formatUTCDate.call(new Date(timestamp * 1000), formatString.date);
};
var localeUTCTime = function(timestamp){
  return _formatUTCTime(new Date(timestamp * 1000), formatString.time);
};
var localeUTCDatetime = function(date){
  if(typeof(date) === 'number') { // timepointer in seconds
    date = new Date(date * 1000);
  }
  else if(Object.prototype.toString.call(date) !== '[object Date]') {
    return '';
  }
  return [_formatUTCDate.call(date), _formatUTCTime(date)].join(' ');
};

var formatDate = function(pure_date, format){
  return _formatDate.call(pure_date, format || formatString.date);
};
var formatTime = function(pure_time){
  return _formatTime(pure_time, formatString.time);
};
var localeDate = function(timestamp){
  return _formatDate.call(new Date(timestamp * 1000));
};
var localeTime = function(timestamp){
  return _formatTime(new Date(timestamp * 1000));
};
var localeDatetime = function(date){
  if(Object.prototype.toString.call(date) !== '[object Date]') {
    return '';
  }
  return [_formatDate.call(date), _formatTime(date)].join(' ');
};

export default {
  formatString: formatString,
  formatUTCDate: formatUTCDate,
  formatUTCTime: formatUTCTime,
  localeUTCDate: localeUTCDate,
  localeUTCTime: localeUTCTime,
  localeUTCDatetime: localeUTCDatetime,
  formatDate: formatDate,
  formatTime: formatTime,
  localeDate: localeDate,
  localeTime: localeTime,
  localeDatetime: localeDatetime
};
