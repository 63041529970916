import detectedBrowser from './detected_browser';

export const ab2str = function ab2str(buf) {
  let bufArray = buf;
  if (detectedBrowser.browser === 'Internet Explorer' && typeof buf.toArray === 'function') {
    bufArray = buf.toArray();
  }

  return [].map.call(new Uint8Array(bufArray), (byte) => String.fromCharCode(byte)).join('');
};

export const str2ab = function str2ab(str) {
  const buf = new ArrayBuffer(str.length); // 2 bytes for each char
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i += 1) {
    bufView[i] = str.charCodeAt(i);
  }
  if (detectedBrowser.browser === 'Internet Explorer') {
    return bufView;
  }
  return buf;
};

export const str2dataStr = function str2dataStr(encodedString) {
  return decodeURIComponent(escape(encodedString));
};
