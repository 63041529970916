<script>
import PluginlessWrapperPlayback from '@/components/viewcell/PluginlessWrapperPlayback.vue';
import { MotionCanvas } from '@vivotek/lib-medama';

export default {
  extends: PluginlessWrapperPlayback,
  name: 'PluginlessWrapperExportPlayback',
  methods: {
    createImage() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          const player = this.viewcell.getPlayer();
          // H.265 needs lazyMode: false

          if (!player) {
            reject();
            return;
          }

          this.motionCanvas = new MotionCanvas({ video: player });

          const humanDetectionArea = this.showPeopleDetectionArea && this.viewinfo.peopleDetectionArea;
          if (this.viewinfo.camera.rule) {
            this.motionCanvas.jsonRules = this.viewinfo.camera.rule;
          }

          if (humanDetectionArea) {
            this.motionCanvas.humanDetectionArea = humanDetectionArea;
          }

          let params = {
            video: player,
            lazyMode: false,
            cover: this.motionCanvas.canvas,
          };

          const { offsetWidth, offsetHeight } = this.$refs.canvasWrapper;

          params = Object.assign(params, {
            stretch: this.stretch,
            width: offsetWidth,
            height: offsetHeight,
          });

          this.image = this.createLensImage(params);
          this.moveWindowPOS(this.pipInfo);

          if (!this.viewinfo.camera.rule || !this.enableVCA) {
            this.image.turnOffCover();
          }

          if (this.isYUVCanvas) {
            this.removePlayerFromWrapper();
          }

          this.appendImageToWrapper(this.image.canvas);

          resolve(this.image);
        });
      });
    },
  }
};
</script>
