import DEFAULT_PLAYBACK_VIEWCELL from '@/constants/defaultPlaybackViewCell';
import Viewcell from '@/models/Viewcell';
import Encoder from '@/models/Encoder';
import LocalTime from '@/utility/local_time';

const theGetters = {
  activeView(state, getters) {
    const viewCell = new Viewcell({
      ...JSON.parse(JSON.stringify(DEFAULT_PLAYBACK_VIEWCELL), new Encoder())
    });
    viewCell.options.begin = new LocalTime({ timestamp: 0, tzoffs: 0 });
    viewCell.options.end = new LocalTime({ timestamp: 0, tzoffs: 0 });
    return state.view.find((view) => view.channel === state.activeEncoderId)
      || viewCell;
  },

  activeViewIndex(state, getters) {
    if (state.activeEncoderId === -1) {
      return -1;
    }
    return state.view.findIndex((view) => view === getters.activeView);
  },

  isEmpty(state) {
    if (state.view.length === 1 && state.view[0].channel === -1) {
      return true;
    }
    return false;
  },

  selectedEncoderCount(state) {
    return state.view.filter((viewcell) => viewcell.channel !== -1).length;
  },

  overallRecordingData(state, getters) {
    return state.view.map((encoder) => encoder.recording_data).flat(1);
  },

  overallAlarmData(state, getters) {
    return state.view.map((encoder) => encoder.alarm_data).flat(1);
  },

  overallBookmarkData(state, getters) {
    return state.view.map((encoder) => encoder.bookmark_data).flat(1);
  },

  overallVCAData(state, getters) {
    return state.view.map((encoder) => encoder.vca_data).flat(1);
  },

  activeViewStatus(state, getters) {
    return getters.activeView.status;
  },

  filteredView(state) {
    return ({ channel, excluded = false }) => {
      let target = [];
      if (excluded && +channel >= 0) {
        target = state.view.filter((viewcell) => +viewcell.channel !== +channel && +viewcell.channel !== -1);
      } else {
        target = typeof (channel) === 'undefined'
          ? state.view.filter((viewcell) => viewcell.channel !== -1)
          : [state.view.find((viewcell) => +viewcell.channel === +channel)];
      }
      return target;
    };
  }
};

export default theGetters;
