import SvgIcon from './SvgIcon.vue';

const install = function(Vue, options) {
  Vue.component(options && options.tagName ? options.tagName : 'SvgIcon', SvgIcon);

  const requireAll = (requireContext) => requireContext.keys().map(requireContext);
  const req = require.context('./svg', false, /\.svg$/);
  requireAll(req);
};

export default { install };
