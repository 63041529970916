/* eslint no-underscore-dangle: ["error", { "allowAfterThis": true }]*/

import MicroEvent from '@vivotek/lib-utility/microevent';
import { Playback, PLUGINFREE } from '@vivotek/lib-medama';
import formatDatetime from '../utils/formatDatetime';
import LiveviewV3 from './liveview';

const genTimeRange = function genTimeRange(startTime, interval/* , isLocalTime, tzoffs*/) {
  const sTime = new Date(startTime);
  const eTime = new Date(sTime.getTime() + interval);

  return `&stime=${formatDatetime(sTime)}&etime=${formatDatetime(eTime)}`;
};

class PlaybackV3 extends LiveviewV3 {
  constructor(options) {
    super(options);
    this.isPause = false;
    this.processor.genTimeRange = genTimeRange;
  }

  createProcessor(options) {
    return new Playback({
      url: this.url,
      rtspChannel: this.getRtspChannel(),
      mute: !!options.mute,
      volume: options.volume || 0,
      getInterval: options.getInterval,
      workerLibde265Path: PLUGINFREE.WORKER_265_SRC,
    });
  }

  onPlay(evt) {
    this.isPause = false;
    super.onPlay(evt);
  }

  getRtspChannel() {
    if (this.rtspChannel) {
      return this.rtspChannel;
    }
    this.rtspChannel = this.rtcConnection.getPlaybackChannel();
    return this.rtspChannel;
  }

  play(startTime) {
    const { processor } = this;
    if (!playback) {
      return Promise.reject();
    }

    return processor.play(startTime);
  }

  pause() {
    if (!this.processor) {
      return;
    }
    this.processor.pause();
    this.isPause = true;
  }

};

export default MicroEvent.mixin(PlaybackV3);
