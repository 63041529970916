import LocalTime from '@/utility/local_time';

const QUERY_INTERVAL = 86400000;

const actions = {
  selectActiveEncoder({ commit, state }, encoderId) {
    commit('setActiveEncoderId', encoderId);
  },

  toggleSelectedEncoders({ commit, rootState, rootGetters }, { selectedEncoders, encoderId }) {
    if (selectedEncoders.indexOf(encoderId) >= 0) {
      commit('addSelectedEncoder', {
        channel: encoderId,
        encoders: rootState.encoder,
        timezone: rootGetters['system/systemTimezone'],
      });
    } else {
      commit('removeSelectedEncoder', {
        channel: encoderId,
        encoders: rootState.encoder,
      });
    }
  },

  updateTime({
    state, commit, getters, dispatch
  }, {
    begin, tzoffs, timestamp, end, query = true, target = []
  }) {
    if (begin) {
      commit('updateTime', {
        target,
        begin,
        end: end || begin.forwardMilliseconds(QUERY_INTERVAL),
        timestamp,
        tzoffs,
      });
    }
    if (!query || !target.length) {
      return Promise.resolve();
    }
    // In order to let dragging timeline to be user friendly,
    // we need to query one more post interval and one more pre interval.
    return Promise.all(
      target.map((viewcell) => {
        // fix for query rules before header mounted
        viewcell.queryVCAEventData();
        return [
          viewcell.queryRecordingData(),
          viewcell.queryAlarmData(),
          viewcell.queryBookmarkData()
        ];
      }).flat()
    );
  },

  changeLayout({ commit }, layout) {
    commit('changeLayout', layout);
  },

  applyUrlQueryToViewcell({ getters, dispatch }, params) {
    const { channel, timestamp, tzoffs } = params;
    dispatch('toggleSelectedEncoders', {
      selectedEncoders: [channel],
      encoderId: channel
    });
    // Note: only consider timezone offset when updateTime mutation
    const localTime = new LocalTime({
      timestamp,
      tzoffs: 0
    });
    return dispatch('updateTime', {
      target: getters.filteredView({ channel }),
      begin: localTime,
      tzoffs,
    });
  }
};

export default actions;
